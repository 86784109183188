<!-- Footer -->
<footer class="page-footer font-small unique-color-dark">
  <div style="background-color:rgb(9,11,13) ;">
    <div class="container">
      <!-- Grid row-->
      <div class="row py-4 d-flex align-items-center">
        <!-- Grid column -->
        <div class="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
          <h6 class="mb-0">Conéctese con nosotros en nuestras redes sociales!</h6>
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-md-6 col-lg-7 text-center text-md-right">
          <!-- Facebook -->
          <a class="fb-ic"><i class="fab fa-facebook-f white-text mr-4"> </i></a>
          <!-- Twitter -->
          <a class="tw-ic"><i class="fab fa-twitter white-text mr-4"> </i></a>
          <!-- Google +-->
          <a class="gplus-ic"><i class="fab fa-google-plus-g white-text mr-4"> </i></a>
          <!--Linkedin -->
          <a class="li-ic"><i class="fab fa-linkedin-in white-text mr-4"> </i></a>
          <!--Instagram-->
          <a class="ins-ic"><i class="fab fa-instagram white-text"></i></a>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row-->
    </div>
  </div>

  <!-- Footer Links -->
  <div class="text-center text-md-left mt-5 myDiv">
    <!-- Grid row -->
    <div class="row mt-3">
      <!-- Grid column -->
      <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
        <!-- Content -->
        <h6 class="text-uppercase font-weight-bold">O&R Soluciones</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <ul class="a">
            <li>Empresa fundada el año 2013 por profesionales de amplia experiencia</li>
            <li>Nos hemos especializado en tareas de Consultoría y desarrollo de Proyectos en el ámbito de las Tecnologías de Información, principalmente en la industria financiera y Bancaria</li>
            <li>Su oferta de valor ha sido privilegiada con la preferencia de empresas del más alto nivel desde sus inicios</li>
        </ul>
      </div>
      <!-- Grid column -->

      <!--
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
        <h6 class="text-uppercase font-weight-bold">Products</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p><a href="#!">MDBootstrap</a></p>
        <p><a href="#!">MDWordPress</a></p>
        <p><a href="#!">BrandFlow</a></p>
        <p><a href="#!">Bootstrap Angular</a></p>
      </div>
      -->

      <!-- Grid column -->
      <!--
      <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
        <h6 class="text-uppercase font-weight-bold">Useful links</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p><a href="#!">Your Account</a></p>
        <p><a href="#!">Become an Affiliate</a></p>
        <p><a href="#!">Shipping Rates</a></p>
        <p><a href="#!">Help</a></p>
      </div>
      -->

      <!-- Grid column -->
      <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Contacto</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p><i class="fas fa-home mr-3"></i> Santiago, Huérfanos 1115 Oficina 303, CL</p>
        <p><i class="fas fa-envelope mr-3"></i> contacto@orsoluciones.cl</p>
        <p><i class="fas fa-phone mr-3"></i> + 56 9 5159 2398</p>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </div>
  <!-- Footer Links -->
  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2021 Copyright:
    <a href="/"> O&R Soluciones</a>
  </div> <!-- Copyright -->
</footer> <!-- Footer -->
